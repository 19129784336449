// pages/_app.js
import { useEffect } from "react";
import { useRouter } from "next/router";
import "../styles/globals.css";
import Layout from "../components/Layout";

// required in future
// import Head from "next/head";
// import Link from "next/link";
// import Badges from "../components/Badges";

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    const handleMessage = async (event) => {
      // if (event.origin !== "http://im.zoomac.localhost:4000") return;

      if (event.data.action === "hideElements") {
        const hideElements = async () => {
          const elements = document.querySelectorAll(".custom-hideable");
          if (elements.length > 0) {
            elements.forEach((element) => {
              element.classList.add("custom-hide");
              element.style.display = "none";
            });
          } else {
            console.warn("No elements with class .custom-hideable found"); // Debugging
            await new Promise((resolve) => setTimeout(resolve, 500)); // Retry after 500ms
            hideElements(); // Recursive call
          }
        };

        await hideElements();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      setTimeout(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("hideElements") === "true") {
          const elements = document.querySelectorAll(".custom-hideable");
          if (elements.length > 0) {
            elements.forEach((element) => {
              element.style.display = "none";
            });
          } else {
            console.warn("No elements with class .custom-hideable found"); // Debugging
          }
        }
      }, 500);
    };

    handleRouteChange(window.location.href);

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  return (
    <>
      <Layout>
        {/* <Badges /> */}
        <Component {...pageProps} />
      </Layout>
    </>
  );
};

export default MyApp;
